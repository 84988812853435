<template>
  <div>
    <img
      class="homeLogo"
      alt="Coopharma"
      title="Coopharma"
      src="../assets/icons/logo-coopharma.png"
    />
    <div class="row justify-content-center mt-5">
      <div class="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6">
        <form v-on:submit.prevent="signIn">
          <h1 class="formTitle text-center">
            {{ languaje == "es" ? "Iniciar Sesión" : "Login" }}
          </h1>
          <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
              <div class="inputContainer">
                <label for="exampleInputEmail1"></label>
                <input
                  type="email"
                  v-if="languaje == 'es'"
                  class="form-control customInput"
                  aria-describedby="emailHelp"
                  placeholder="Correo Electronico"
                  v-model="email"
                />
                <input
                  v-else
                  type="email"
                  class="form-control customInput"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  v-model="email"
                />
              </div>
              <div class="inputContainer">
                <label for="exampleInputPassword1"></label>
                <input
                  v-if="languaje == 'es'"
                  type="password"
                  class="form-control customInput"
                  placeholder="Contraseña"
                  v-model="password"
                />
                <input
                  v-else
                  type="password"
                  class="form-control customInput"
                  placeholder="Password"
                  v-model="password"
                />
              </div>
              <router-link class="forgot-btn darkFont" to="/forgotPassword">
                <h5 class="text-center mt-5 darkFont">
                  {{
                    languaje == "es"
                      ? "¿Olvidaste tu contraseña?"
                      : "Did you forget your password?"
                  }}
                </h5>
              </router-link>
              <router-link class="forgot-btn darkFont" to="/register">
                <h5 class="text-center mt-5 darkFont">
                  {{
                    languaje == "es"
                      ? "No tienes cuenta? Registrate"
                      : "You don't have a account? Sign up"
                  }}
                </h5>
              </router-link>
              <input
                style="margin-bottom:20px"
                class="btn darkBackground lightFont registerButton2"
                value="Login"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="backdrop" class="loader">
      <div :class="loader ? 'loader-show' : ''" class="loader-container ">
        <RotateSquare2 />
        <p class="loader-text">
          {{ languaje == "es" ? "Cargando..." : "Loading..." }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Parse from "parse";
import { RotateSquare2 } from "vue-loading-spinner";
export default {
  name: "Login",
  components: {
    RotateSquare2
  },
  data() {
    return {
      backdrop: false,
      loader: false,
      email: "",
      password: "",
      languaje: ""
    };
  },
  mounted() {
    this.languaje = navigator.language || navigator.userLanguage;
    this.languaje = this.languaje.substr(0, 2);
  },
  methods: {
    signIn() {
      localStorage.removeItem("usuarioFarmacia");
      localStorage.removeItem("archivoEmail");
      this.backdrop = true;
      this.loader = true;
      if (this.email === "" || this.password === "") {
        this.backdrop = false;
        this.loader = false;
        alert(
          this.languaje == "es"
            ? "¡Los campos son requeridos!"
            : "Fields are required!"
        );
      } else {
        Parse.User.logIn(this.email, this.password)
          .then(resp => {
            if (resp.get("isAdmin") === true) {
              localStorage.setItem(
                "usuarioFarmacia",
                JSON.stringify({
                  admin: true,
                  email: this.email,
                  name: resp.get("fullName"),
                  rol: "E",
                  status: true,
                  editable: false,
                  accountId: resp.get("stripeAccountId"),
                  stripeAccountId: resp.get("stripeAccountId") ? true : false
                })
              );

              this.$router.push("/orders");
              this.backdrop = false;
              this.loader = false;
            } else if (resp.get("superAdmin") === true) {
              this.$router.push("/farmacies");
              this.backdrop = false;
              this.loader = false;
            } else {
              this.backdrop = false;
              this.loader = false;
              Parse.User.logOut();
            }
          })
          .catch(() => {
            const query = new Parse.Query("storeUsers");
            const query2 = new Parse.Query("_User");
            query.include("store");
            query.equalTo("email", this.email);
            query.equalTo("password", btoa(this.password));
            query.equalTo("status", true);
            query.equalTo("deleted", false);
            query
              .first()
              .then(user => {
                query2.equalTo(
                  "objectId",
                  user.get("store").get("employee").id
                );
                query2.first().then(uzer => {
                  localStorage.setItem(
                    "usuarioFarmacia",
                    JSON.stringify({
                      name: user.get("name"),
                      last: user.get("last"),
                      admin: user.get("admin"),
                      email: user.get("email"),
                      crapld: user.get("password"),
                      phone: user.get("phone"),
                      rol: user.get("rol"),
                      status: user.get("status"),
                      editable: true,
                      accountId: uzer.get("stripeAccountId"),
                      stripeAccountId: uzer.get("stripeAccountId")
                        ? true
                        : false
                    })
                  );
                  Parse.User.logIn(
                    uzer.get("username"),
                    atob(user.get("store").get("logkey"))
                  ).then(resp => {
                    if (resp.get("isAdmin") === true) {
                      this.$router.push("/orders");
                      this.backdrop = false;
                      this.loader = false;
                    } else if (resp.get("superAdmin") === true) {
                      this.$router.push("/adminStores");
                      this.backdrop = false;
                      this.loader = false;
                    } else {
                      Parse.User.logOut();
                    }
                  });
                });
              })
              .catch(() => {
                this.backdrop = false;
                this.loader = false;
                alert(
                  this.languaje == "es"
                    ? "Correo Electronico o Contraseña incorrectos."
                    : "Email or Password incorrect."
                );
              });
          });
      }
    }
  }
};
</script>
<style scoped>
.homeLogo {
  margin: auto;
  width: 250px;
  display: block;
  padding-top: 8vh;
}
.loader-container {
  background: #fff;
  width: 400px;
  height: 300px;
  transition: 0.3s all ease;
  transform: scale(0);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loader-show {
  transform: scale(1) !important;
}

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  overflow: hidden;
  display: flex;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.loader-text {
  font-family: "segoe ui";
  font-style: italic;
  font-size: 20px;
}
.darkBackground {
  background-color: #1e1e1e !important;
}
.darkFont {
  color: #1e1e1e !important;
}
.lightBackground {
  background-color: #f6f6f6 !important;
}
.lightFont {
  color: #f6f6f6 !important;
}
.cursorPointer {
  cursor: pointer !important;
}
.formTitle {
  font-weight: 600;
  color: #0e9390;
}
.inputContainer {
  margin: 0px;
  padding: 0px;
  height: 55px;
  margin-top: 1.5vh;
}
.inputForm {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 55px;
  margin: auto;
  display: block;
  border-style: none;
  border: 2px solid #0e9390;
  border-radius: 10px;
  background-color: #e0e0e0;
  padding-left: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  padding-left: 20px;
  letter-spacing: 0.1vw;
}
.registerButton2 {
  width: 100%;
  height: 50px;
  margin-top: 5vh;
  border-style: none;
  background-color: #000000;
  color: #e0e0e0;
  letter-spacing: 0.2vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.registerIcon {
  width: 50%;
  margin: auto;
  display: block;
}
.customInput {
  width: 100%;
  height: 45px;
  border-radius: 0px;
  border-style: none;
  border-bottom: 2px solid #0e9390;
}
</style>
